import React from "react";
import "./style.scss";

const ParameterDropdown = ({ label, selectValue, paramOptions, onChange }) => {
  const validOptions = paramOptions.filter((option) => option != null);
  if (validOptions.length > 0) {
    return (
      <div className="parameter-dropdown">
        <label>{label}: </label>
        <select value={selectValue || ""} onChange={onChange}>
          {paramOptions.map((option) => (
            <option key={option} value={option}>
              {Math.floor(option)} <span> см.</span>
            </option>
          ))}
        </select>
      </div>
    );
  } else return null;
};

export default ParameterDropdown;
