import React, { useEffect, useState } from "react";
import axios from "axios";
import CategotyButton from "../../components/buttons/CategoryButton/CategoryButton";
import sofaLogo from "../../assets/icons/sofa.png";
import { useCategory } from "../../context/CategoryContext";
import { useSubcategory } from "../../context/SubcategoryContext";
import { useBreadcrumb } from "../../context/BreadcrumbContext";
import { useNavigate } from "react-router-dom";

import "./style.scss";

const Categories = () => {
  const [categories, setCategories] = useState();
  const url = process.env.REACT_APP_BACKEND_URL;
  const { setSelectedCategoryId } = useCategory();
  const { setSelectedSubcategoryId } = useSubcategory();
  const { setCategoryCrumb } = useBreadcrumb();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(url + "/api/categories/", {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchCategories();
  }, [url]);

  return (
    <div className="categories">
      {categories &&
        categories.map((category) => (
          <CategotyButton
            title={category.title}
            icon={sofaLogo}
            key={category.id}
            onClick={() => {
              setSelectedSubcategoryId(null);
              setSelectedCategoryId(category.id);
              setCategoryCrumb(category);
              navigate(`/categories/${category.id}`);
            }}
          />
        ))}
    </div>
  );
};

export default Categories;
