import React from "react";
import CallMeBack from "../../components/CallMeBack/CallMeBack";
import "./style.scss";

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <section>
          <p>Ласкаво просимо до N-style</p>
          <p>
            Ми - команда професіоналів, яка створює найкращі дивани та м'які
            меблі для вашого комфорту та стилю. Наша місія полягає в тому, щоб
            зробити ваш дім щасливішим і зручнішим місцем для вас та вашої
            родини.
          </p>
          <p>Наше виробництво</p>
          <p>
            Ми пишаємося кожним етапом виробництва, і кінцевий результат - це
            продукти, які приносять радість і комфорт нашим клієнтам впродовж
            багатьох років.
          </p>
          <p>
            Ми завжди готові відповісти на ваші питання та допомогти вам обрати
            ідеальні меблі для вашого дому. Зв'яжіться з нами сьогодні і
            дозвольте нам створити для вас найкращий простір для відпочинку та
            релаксу.
          </p>
        </section>
        <CallMeBack />
      </div>
    </div>
  );
};

export default About;
