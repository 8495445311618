import React, { useState } from "react";
import axios from "axios";
import SimpleButton from "../buttons/SimpleButton/SimpleButton";
import "./style.scss";

const CallMeBack = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getCsrfToken = () => {
    // Try to retrieve CSRF token from Django cookies
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith("csrftoken="))
      .split("=")[1];
  };

  const handleSubmit = async () => {
    if (isSubmitting) return;

    if (!/^\d{10,12}$/.test(phoneNumber)) {
      setMessage("Введіть правильний номер телефону");
      return;
    }

    setMessage("");

    try {
      const csrfToken = getCsrfToken();
      // Include CSRF token in headers for the POST request
      const config = {
        withCredentials: true,
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
      };

      // Replace with your backend endpoint
      const response = await axios.post(
        "http://localhost:8000/api/request_callback/",
        { phone: phoneNumber },
        config
      );

      if (response.status === 200) {
        setMessage("Ми Вам зателефонуємо!");
        setIsSubmitting(true);
      } else {
        setMessage("Щось пішло не так. Спробуйте пізніше!");
      }
    } catch (error) {
      setMessage("Щось пішло не так. Спробуйте пізніше!");
    }
  };

  return (
    <div className="callmeback">
      <h3>Запишіть Ваш номер і наш менеджер зателефонує</h3>
      <input
        type="tel"
        placeholder="Введіть номер телефону"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
      />
      {message && <p>{message}</p>}
      <SimpleButton
        className="submit"
        onClick={handleSubmit}
        title={"Зв'язатись"}
        disabled={isSubmitting}
      />
    </div>
  );
};

export default CallMeBack;
