import { useState, useEffect } from "react";
import arrowRight from "../../assets/icons/chevron-right-solid.svg";
import { useNavigate } from "react-router-dom";
import "./style.scss";

export default function Carousel({ items }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_BACKEND_URL;

  const handleElementClick = (id) => {
    navigate(`/product/${id}`);
  };

  const goToNext = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex + 2;
      return newIndex < items.length ? newIndex : 0;
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 9000);

    return () => clearInterval(interval);
  }, []);

  const renderItem = (item) => {
    return (
      <div className="element" onClick={() => handleElementClick(item.id)}>
        <img src={url + item.images[0]?.image} alt={item.name} />
        <span>{item.title}</span>
        {item.is_on_sale && (
          <span className="discount">Знижка {item.discount}%</span>
        )}
      </div>
    );
  };

  return (
    <div
      id="carouselExampleControls"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        {items.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === activeIndex ? "active" : ""}`}
          >
            <div className="carousel-card">
              {renderItem(item)}
              {items[index + 1] && renderItem(items[index + 1])}
              <button className="next" onClick={goToNext}>
                <img className="arrow" src={arrowRight} alt="arrow" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
