import React from "react";
import "./style.scss";

const CartParameters = ({ size, color, fabric }) => {
  return (
    <section className="cart-parameters">
      {size?.width && (
        <div>
          <span>Ширина: </span>
          <span className="number">{Math.floor(size.width)} см</span>
        </div>
      )}
      {size?.length && (
        <div>
          <span>Довжина: </span>
          <span className="number">{Math.floor(size.length)} см</span>
        </div>
      )}
      {size?.height && (
        <div>
          <span>Висота: </span>
          <span className="number">{Math.floor(size.height)} см</span>
        </div>
      )}
      {size?.depth && (
        <div>
          <span>Глибина: </span>
          <span className="number">{Math.floor(size.depth)} см</span>
        </div>
      )}
      {color && (
        <div>
          <span>Колір: </span>
          <span className="number">{color}</span>
        </div>
      )}
      {fabric && (
        <div>
          <span>Тканина: </span>
          <span className="number">{fabric}</span>
        </div>
      )}
    </section>
  );
};

export default CartParameters;
