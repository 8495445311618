// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart {
  margin-top: 1rem;
  min-height: 90vh;
}
.cart .empty-cart {
  margin: 5rem 0;
  display: flex;
  height: 50vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
.cart .card {
  border-radius: 0;
  border: none;
  padding-bottom: 1rem;
  border-bottom: 1px solid #490515;
  margin: 1rem;
}
.cart .card .board {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart .card .board .name-price {
  display: flex;
  flex-direction: column;
}
.cart .card .board .trash-icon {
  background: none;
  border: none;
  width: 2rem;
  min-height: 2rem;
}
.cart .card .board .trash-icon img {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Cart/style.scss"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,gBAAA;AADF;AAEE;EACE,cAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,6BAAA;EACA,kBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,YAAA;EACA,oBAAA;EACA,gCAAA;EACA,YAAA;AADJ;AAEI;EACE,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAN;AACM;EACE,aAAA;EACA,sBAAA;AACR;AACM;EACE,gBAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;AACR;AAAQ;EACE,WAAA;EACA,YAAA;AAEV","sourcesContent":["@import \"../../styles/variables\";\n\n.cart {\n  margin-top: 1rem;\n  min-height: 90vh;\n  .empty-cart {\n    margin: 5rem 0;\n    display: flex;\n    height: 50vh;\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-evenly;\n    text-align: center;\n  }\n\n  .card {\n    border-radius: 0;\n    border: none;\n    padding-bottom: 1rem;\n    border-bottom: 1px solid $main-purple;\n    margin: 1rem;\n    .board {\n      margin-top: 1rem;\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n      .name-price {\n        display: flex;\n        flex-direction: column;\n      }\n      .trash-icon {\n        background: none;\n        border: none;\n        width: 2rem;\n        min-height: 2rem;\n        img {\n          width: 100%;\n          height: 100%;\n        }\n      }\n    }\n  }\n}\n\n@media (min-width: 576px) {\n}\n\n@media (min-width: 768px) {\n}\n\n@media (min-width: 992px) {\n}\n\n@media (min-width: 1200px) {\n}\n\n@media (min-width: 1400px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
