// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
  margin: 1rem 0;
  /* Style for the dividers */
  /* Hover style for clickable breadcrumb items only */
  /* Active item style */
}
.breadcrumb .breadcrumb-item {
  padding: 0;
  font-size: 0.6rem;
}
.breadcrumb .breadcrumb-item a {
  color: black;
  margin: 0;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "/";
}
.breadcrumb .breadcrumb-item:hover {
  font-weight: 600;
  text-decoration: none; /* optional, if you want to remove the underline on hover */
}
.breadcrumb .breadcrumb-item.active,
.breadcrumb .breadcrumb-item:last-of-type {
  font-weight: 600;
  color: black;
  /* Other styles for the active item, but no hover effect */
}

@media (min-width: 768px) {
  .breadcrumb .breadcrumb-item {
    font-size: 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumbs/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EAYA,2BAAA;EAKA,oDAAA;EAMA,sBAAA;AAnBF;AAFE;EACE,UAAA;EACA,iBAAA;AAIJ;AADE;EACE,YAAA;EACA,SAAA;AAGJ;AACE;EACE,YAAA;AACJ;AAGE;EACE,gBAAA;EACA,qBAAA,EAAA,2DAAA;AADJ;AAKE;;EAEE,gBAAA;EACA,YAAA;EACA,0DAAA;AAHJ;;AAOA;EAEI;IACE,eAAA;EALJ;AACF","sourcesContent":[".breadcrumb {\n  margin: 1rem 0;\n\n  .breadcrumb-item {\n    padding: 0;\n    font-size: 0.6rem;\n  }\n\n  .breadcrumb-item a {\n    color: black;\n    margin: 0;\n  }\n\n  /* Style for the dividers */\n  .breadcrumb-item + .breadcrumb-item::before {\n    content: \"/\";\n  }\n\n  /* Hover style for clickable breadcrumb items only */\n  .breadcrumb-item:hover {\n    font-weight: 600;\n    text-decoration: none; /* optional, if you want to remove the underline on hover */\n  }\n\n  /* Active item style */\n  .breadcrumb-item.active,\n  .breadcrumb-item:last-of-type {\n    font-weight: 600;\n    color: black;\n    /* Other styles for the active item, but no hover effect */\n  }\n}\n\n@media (min-width: 768px) {\n  .breadcrumb {\n    .breadcrumb-item {\n      font-size: 1rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
