import React from "react";
import smalllogo from "../../assets/logos/smallogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import CartIcon from "./CartIcon/CartIcon";
import { useRef } from "react";
import "./style.scss";

const Header = () => {
  const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  function collapseNav() {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg fixed-top" data-bs-theme="dark">
        <div className="container-fluid justify-content-between">
          <button
            ref={navButton}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon
              className="bar-icon"
              icon={faBars}
              style={{ color: "#490515" }}
            />
          </button>
          <div
            ref={linksContainerRef}
            className="collapse navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link onClick={collapseNav} to="/" className="nav-link">
                  Головна
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={collapseNav} to="/about" className="nav-link">
                  Про нас
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={collapseNav} to="/catalog" className="nav-link">
                  Каталог
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={collapseNav} to="/contacts" className="nav-link">
                  Контакти
                </Link>
              </li>

              <li className="d-none d-lg-block cart-icon-wrapper">
                <CartIcon />
              </li>
            </ul>
          </div>
          <Link className="navbar-brand" onClick={collapseNav} to="/">
            <img src={smalllogo} alt="small logo" className="header-logo " />
          </Link>
          <li className="d-block d-lg-none">
            <CartIcon />
          </li>
        </div>
      </nav>
    </>
  );
};

export default Header;
