import React from "react";
import { CategoryProvider } from "./CategoryContext";
import { SubcategoryProvider } from "./SubcategoryContext";
import { BreadcrumbProvider } from "./BreadcrumbContext";
import { CartProvider } from "./CartContext";
import { ProductProvider } from "./ProductContext";

const AppContextProvider = ({ children }) => {
  return (
    <CategoryProvider>
      <SubcategoryProvider>
        <BreadcrumbProvider>
          <ProductProvider>
            <CartProvider>{children}</CartProvider>
          </ProductProvider>
        </BreadcrumbProvider>
      </SubcategoryProvider>
    </CategoryProvider>
  );
};

export default AppContextProvider;
