import React, { useEffect, useState } from "react";
import axios from "axios";
import { useCategory } from "../../context/CategoryContext";
import { useSubcategory } from "../../context/SubcategoryContext";
import { useBreadcrumb } from "../../context/BreadcrumbContext";
import SubcategoryButton from "./SubcategoryButton/SubcategoryButton";
import { useNavigate } from "react-router-dom";

const Subcategories = () => {
  const { selectedCategoryId } = useCategory();
  const [subcategories, setSubcategories] = useState([]);
  const url = process.env.REACT_APP_BACKEND_URL;
  const { setSelectedSubcategoryId } = useSubcategory();
  const { setSubcategoryCrumb } = useBreadcrumb();
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedCategoryId) {
      const fetchSubcategories = async () => {
        try {
          const response = await axios.get(
            `${url}/api/categories/${selectedCategoryId}/subcategories/`
          );
          setSubcategories(response.data);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };

      fetchSubcategories();
    }
  }, [selectedCategoryId, url]);
  return (
    <div className="subcategories">
      {subcategories &&
        subcategories.map((subcategory) => (
          <SubcategoryButton
            key={subcategory.id}
            title={subcategory.title}
            onClick={() => {
              setSelectedSubcategoryId(subcategory.id);
              setSubcategoryCrumb(subcategory);
              navigate(
                `/categories/${selectedCategoryId}/subcategory/${subcategory.id}`
              );
            }}
          />
        ))}
    </div>
  );
};

export default Subcategories;
