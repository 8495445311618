import CompositeProductCard from "./CompositeProductCard/CompositeProductCard";
import "./style.scss";

export default function Components({ products, addProductToSelection }) {
  return (
    <section className="components">
      <h2>Комплектуючі:</h2>
      <div className="row">
        {products.map((item, index) => (
          <CompositeProductCard
            title={item.title && item.title}
            image={item.images[0] && item.images[0].image}
            price={item.price && item.price}
            key={index}
            addProductToSelection={() => addProductToSelection(item)}
          />
        ))}
      </div>
    </section>
  );
}
