import useProposals from "../../hooks/dataFetching/useProposals";
import Carousel from "../HomeCarousel/Carousel";
import "./style.scss";

export default function Proposals({ className }) {
  const { proposals } = useProposals();
  return (
    <section className={`proposals container ${className}`}>
      <h4>Пропозиції</h4>
      {proposals && <Carousel items={proposals} />}
    </section>
  );
}
