import React from "react";
import sofaLogo from "../../../assets/icons/sofa.svg";
import bedLogo from "../../../assets/icons/bedLogo.svg";
import angles from "../../../assets/icons/angles.svg";
import matras from "../../../assets/icons/matras.png";
import tables from "../../../assets/icons/tables.svg";
import kitchen from "../../../assets/icons/kitchen.svg";
import vitalnya from "../../../assets/icons/vitalnya.svg";
import predpokiy from "../../../assets/icons/predpokiy.svg";
import shafa from "../../../assets/icons/shafa.svg";
import comod from "../../../assets/icons/comod.svg";

import "./style.scss";

const logoMapping = {
  Дивани: sofaLogo,
  Cпальні: bedLogo,
  "Кутові дивани": angles,
  Матраци: matras,
  "Столи та стільці": tables,
  "Кухонні кутки": kitchen,
  Вітальні: vitalnya,
  Передпокої: predpokiy,
  Шафи: shafa,
  "Комоди та тумби": comod,
};

const CategoryButton = ({ title, onClick }) => {
  const icon = logoMapping[title] || bedLogo; // Default to sofaLogo if no match is found

  return (
    <button className="category-button" onClick={onClick}>
      <img className="icon-container" src={icon} alt={title} />
      <p>{title}</p>
    </button>
  );
};

export default CategoryButton;
