import React, { useState, useEffect } from "react";
import "./style.scss";

const ImageGallery = ({ images }) => {
  const [mainImage, setMainImage] = useState(images[0]?.image);

  useEffect(() => {
    if (images.length > 0) {
      setMainImage(images[0].image);
    }
  }, [images]);

  const updateMainImage = (newImage) => {
    setMainImage(newImage);
  };

  return (
    <div className="image-gallery">
      <div className="main-image">
        <img src={mainImage} className="d-block w-100" alt="Main Display" />
      </div>
      <div className="thumbnail-container">
        {images.map((img, index) => (
          <img
            key={index}
            src={img.image}
            className="image-thumbnail"
            alt={`Thumbnail ${index + 1}`}
            onClick={() => updateMainImage(img.image)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
