import React from "react";
import "./style.scss";

const SubcategoryButton = ({ title, onClick }) => {
  return (
    <button className="subcategory-button" onClick={onClick}>
      {title}
    </button>
  );
};

export default SubcategoryButton;
