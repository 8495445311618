import React from "react";
import DOMPurify from "dompurify";
import "./style.scss";

const Details = ({ details }) => {
  const createMarkup = (htmlContent) => {
    return { __html: DOMPurify.sanitize(htmlContent) };
  };

  return (
    <>
      {details && (
        <div className="details">
          <h4>Опис:</h4>

          <div
            className="line"
            dangerouslySetInnerHTML={createMarkup(details)}
          />
        </div>
      )}
    </>
  );
};

export default Details;
