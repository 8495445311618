import SimpleButton from "../../../components/buttons/SimpleButton/SimpleButton";
import CartModal from "../CartModal/CartModal";
import "./style.scss";

export default function CheckoutSection({
  totalPrice,
  setModalOpen,
  modalOpen,
  handleOrder,
}) {
  return (
    <section className="checkout-section">
      <div className="wrapper">
        <div className="total-price">
          <span>Загальна сума:</span>
          <h4>{totalPrice} грн</h4>
        </div>
        <SimpleButton
          className="checkout-btn"
          onClick={() => setModalOpen(true)}
          title={"Замовити"}
        ></SimpleButton>
      </div>
      <CartModal
        isOpen={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleOrder={handleOrder}
      />
    </section>
  );
}
