import React, { useState, useEffect } from "react";
import ParameterDropdown from "./ParameterDropdown/ParameterDropdown";

const Parameters = ({ parameters, onParameterSelected }) => {
  // eslint-disable-next-line no-unused-vars
  const [selectedParameterIndex, setSelectedParameterIndex] = useState(0);
  const [selectedParameter, setSelectedParameter] = useState(null);

  useEffect(() => {
    if (parameters.length > 0) {
      setSelectedParameter(parameters[0]);
      setSelectedParameterIndex(0);
      onParameterSelected(parameters[0]);
    } else {
      setSelectedParameter({});
      onParameterSelected({});
    }
  }, [parameters]);

  const getUniquePropertyValues = (property) => {
    return [...new Set(parameters.map((param) => param[property]))];
  };

  const widthOptions = getUniquePropertyValues("width");
  const heightOptions = getUniquePropertyValues("height");
  const depthOptions = getUniquePropertyValues("depth");
  const lengthOptions = getUniquePropertyValues("length");

  const handleParameterChange = (property, value) => {
    const newSelectedParameter = parameters.find(
      (param) =>
        (property === "width" || param.width === selectedParameter.width) &&
        (property === "height" || param.height === selectedParameter.height) &&
        (property === "depth" || param.depth === selectedParameter.depth) &&
        (property === "length" || param.length === selectedParameter.length) &&
        param[property] === value
    );

    if (newSelectedParameter) {
      setSelectedParameter(newSelectedParameter);
      const newIndex = parameters.indexOf(newSelectedParameter);
      setSelectedParameterIndex(newIndex);
      onParameterSelected(newSelectedParameter);
    }
  };

  if (parameters.length > 0)
    return (
      <section className="parameters">
        <h4>Характеристики</h4>
        <div>
          <ParameterDropdown
            label={"Ширина"}
            selectValue={selectedParameter?.width}
            onChange={(e) => handleParameterChange("width", e.target.value)}
            paramOptions={widthOptions}
          />
          <ParameterDropdown
            label={"Довжина"}
            selectValue={selectedParameter?.length}
            onChange={(e) => handleParameterChange("length", e.target.value)}
            paramOptions={lengthOptions}
          />
          <ParameterDropdown
            label={"Висота"}
            selectValue={selectedParameter?.height}
            onChange={(e) => handleParameterChange("height", e.target.value)}
            paramOptions={heightOptions}
          />
          <ParameterDropdown
            label={"Глибина"}
            selectValue={selectedParameter?.depth}
            onChange={(e) => handleParameterChange("depth", e.target.value)}
            paramOptions={depthOptions}
          />
        </div>
      </section>
    );
};

export default Parameters;
