export default function PopoverImage({ src, position, width, height, rect }) {
  if (!src) return null;

  const style = {
    position: "fixed",
    top: `${position.y}px`,
    left: `${position.x}px`,
    transform: "translate(-50%, 0)", // Center horizontally
    zIndex: 1000,
    padding: "1rem",
    borderRadius: "50%",
    // Additional styling as needed
  };

  return <img src={src} alt="Hover" style={style} width="200" height="200" />;
}
