import "./style.scss";

export default function Pagination({ setCurrentPage, totalPages }) {
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const handlePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <section className="pagination">
      <div>
        {pageNumbers.length > 1 &&
          pageNumbers.map((pageNumber) => (
            <button
              key={pageNumber}
              className="page-button"
              onClick={() => handlePage(pageNumber)}
            >
              {pageNumber}
            </button>
          ))}
      </div>
    </section>
  );
}
