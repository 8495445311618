import SimpleButton from "../../../../components/buttons/SimpleButton/SimpleButton";
import "./style.scss";

export default function CompositeProductCard({
  title,
  image,
  price,
  addProductToSelection,
}) {
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="composite-product-card ">
        <img src={image} alt={title}></img>
        <div className="details">
          <div className="text">
            <span>{title}</span>
            <span>{price} грн</span>
          </div>
          <SimpleButton
            title={"Додати"}
            className={"buy-button"}
            onClick={addProductToSelection}
          />
        </div>
      </div>
    </div>
  );
}
