import React from "react";
import { Link } from "react-router-dom";
import cartIcon from "../../../assets/icons/cart.svg";
import { useCart } from "../../../context/CartContext";
import "./style.scss";

const CartIcon = () => {
  const { cartItems } = useCart();

  return (
    <div className="cart-icon-component">
      <Link to="/cart" className="cart-icon">
        <img src={cartIcon} alt="Іконка кошика" />
        {cartItems.length > 0 && (
          <div className="number">{cartItems.length}</div>
        )}
      </Link>
    </div>
  );
};

export default CartIcon;
