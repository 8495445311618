import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagination from "../Pagination/Pagination";
import Card from "../Card/Card";
import "./style.scss";

export default function Search() {
  const [query, setQuery] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const url = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchProducts = async (page) => {
      try {
        const response = await axios.get(
          `${url}/api/products/?search=${query}&page=${page}`
        );
        setSearchedProducts(response.data.results);
        const totalPages = Math.ceil(response.data.count / 12);
        setTotalPages(totalPages);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    if (query.length > 2) {
      fetchProducts(currentPage);
    } else {
      setSearchedProducts([]);
      setTotalPages(1);
    }
  }, [currentPage, query, url]);

  return (
    <section className="search">
      <div className="search-section">
        <input
          className="search-input"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Пошук..."
        />
      </div>

      <div className="row result">
        {searchedProducts.length > 0
          ? searchedProducts.map((product) => (
              <Card key={product.id} product={product} />
            ))
          : query.length > 2 && (
              <h3 className="nothing-found">
                Ми нічого не зайшли за вашим запитом. <br />
                Перегляньте наші пропозиції в каталозі! <br />
                <Link to="/catalog">Перейти до каталогу</Link>
              </h3>
            )}
      </div>

      {totalPages > 1 && (
        <div>
          <Pagination
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </section>
  );
}
