import React from "react";
import "./style.scss";

const SimpleButton = ({ title, onClick, className }) => {
  let buttonClassName = "simple-button";

  if (className) {
    buttonClassName += ` ${className}`;
  }

  return (
    <button className={buttonClassName} onClick={onClick}>
      {title}
    </button>
  );
};

export default SimpleButton;
