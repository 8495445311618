import React, { useState } from "react";
import PopoverImage from "./PopoverImage/PopoverImage";
import "./style.scss";

const GroupedItems = ({ items, title, onItemSelected }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const [popoverShow, setPopoverShow] = useState(false);
  const [popoverImage, setPopoverImage] = useState("");
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });

  const popoverHeight = 200;
  const popoverWidth = 200;

  const handleMouseEnter = (e, image) => {
    const targetRect = e.target.getBoundingClientRect();
    const popoverWidth = 200;
    const popoverHeight = 200;

    let popoverX = targetRect.left + targetRect.width / 2;

    let popoverY = targetRect.top;

    if (popoverX - popoverWidth / 2 < 0) {
      popoverX = popoverWidth / 2;
    }

    if (popoverX + popoverWidth / 2 > window.innerWidth) {
      popoverX = window.innerWidth - popoverWidth / 2;
    }

    popoverX -= window.scrollX;
    popoverY -= popoverHeight;

    setPopoverShow(true);
    setPopoverImage(image);
    setPopoverPosition({ x: popoverX, y: popoverY });
  };

  const handleMouseLeave = () => {
    setPopoverShow(false);
  };

  const handleItemClick = (itemTitle, itemPrice) => {
    setSelectedItem(itemTitle);
    onItemSelected(itemTitle, itemPrice);
  };

  const groupedItems = items.reduce((acc, item) => {
    acc[item.category_name] = acc[item.category_name] || [];
    acc[item.category_name].push(item);
    return acc;
  }, {});

  const sortedCategories = Object.keys(groupedItems).sort();
  sortedCategories.forEach((category) => {
    groupedItems[category].sort((a, b) => a.title.localeCompare(b.title));
  });

  return (
    <section className="fabrics-and-colors">
      <h4>{title}:</h4>
      {popoverShow && (
        <PopoverImage
          src={popoverImage}
          position={popoverPosition}
          width={popoverWidth}
          height={popoverHeight}
        />
      )}
      {sortedCategories.map((category) => (
        <div key={category}>
          <span>{category}</span>
          <div className="board">
            {groupedItems[category].map((item) => (
              <div
                className={`board-item ${
                  selectedItem === item.title ? "selected" : ""
                }`}
                key={item.title}
                onClick={() => handleItemClick(item.title, item.price)}
              >
                <img
                  src={item.image}
                  alt={item.title}
                  onMouseEnter={(e) => handleMouseEnter(e, item.image)}
                  onMouseLeave={handleMouseLeave}
                />
                <p>{item.title}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

const Fabrics = ({ fabrics, onItemSelected }) => {
  return (
    <GroupedItems
      items={fabrics}
      title="Тканини"
      onItemSelected={onItemSelected}
    />
  );
};

const Colors = ({ colors, onItemSelected }) => {
  return (
    <GroupedItems
      items={colors}
      title="Кольори"
      onItemSelected={onItemSelected}
    />
  );
};

export { Fabrics, Colors };
