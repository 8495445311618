import "./style.scss";

export default function Badge({ icon, title }) {
  return (
    <div className="col-12 col-md-4 col-lg-3">
      <div className="badge-card">
        <div className="icon-wrapper">
          <img src={icon} alt={title} />
        </div>
        <span className="badge-title">{title}</span>
      </div>
    </div>
  );
}
