import React, { useState } from "react";
import SimpleButton from "../../../components/buttons/SimpleButton/SimpleButton";
import "./style.scss";

const CartModal = ({ isOpen, handleClose, handleOrder }) => {
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const validate = () => {
    let isValid = true;
    if (!customerName.trim()) {
      setNameError("Будь ласка, введіть ім'я");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!/^\d{10,12}$/.test(customerPhone)) {
      setPhoneError("Введіть номер телефону");
      isValid = false;
    } else {
      setPhoneError("");
    }

    return isValid;
  };

  const submitOrder = (e) => {
    e.preventDefault();
    const isValid = validate();

    if (isValid) {
      handleOrder(customerName, customerPhone);
    }
  };
  return (
    <div
      className={`cart-modal modal fade ${isOpen ? "show" : ""}`}
      style={{ display: isOpen ? "block" : "none" }}
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Замовлення</h5>
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={submitOrder}>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="customerName"
                  placeholder="Ім'я"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
                {nameError && <p className="error-message">{nameError}</p>}
              </div>
              <div className="mb-3">
                <input
                  type="tel"
                  className="form-control"
                  id="customerPhone"
                  placeholder="Номер телефону"
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                />
                {phoneError && <p className="error-message">{phoneError}</p>}
              </div>
              <SimpleButton
                type="submit"
                title={"Оформити замовлення"}
                className="checkout-btn"
              ></SimpleButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartModal;
