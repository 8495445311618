// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map {
  margin: 0 auto;
  width: 100%;
  height: 400px;
  margin-bottom: 1rem;
}
.map .pin {
  height: 3rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Map/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,YAAA;AAEJ","sourcesContent":[".map {\n  margin: 0 auto;\n  width: 100%;\n  height: 400px;\n  margin-bottom: 1rem;\n  .pin {\n    height: 3rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
