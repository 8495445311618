// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts .first-section {
  margin-bottom: 2rem;
}
.contacts .social-icon {
  width: 2rem;
  margin-right: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Contacts/style.scss"],"names":[],"mappings":"AAGE;EACE,mBAAA;AAFJ;AAIE;EACE,WAAA;EACA,kBAAA;AAFJ","sourcesContent":["@import \"../../styles/variables\";\n\n.contacts {\n  .first-section {\n    margin-bottom: 2rem;\n  }\n  .social-icon {\n    width: 2rem;\n    margin-right: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
