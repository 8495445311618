import "./style.scss";
import React, { useEffect } from "react";

function ProductAddedModal({ show, onHide }) {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onHide();
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [show, onHide]);

  if (!show) return null;

  return (
    <div className="modal-added modal show d-block" tabIndex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="btn-close"
              onClick={onHide}
            ></button>
          </div>
          <div className="modal-body">
            <h4>Товар додано до кошика!</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductAddedModal;
