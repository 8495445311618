import { Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./pages/About/About";
import Catalog from "./pages/Catalog/Catalog";
import Product from "./pages/Product/Product";
import CompositeProduct from "./pages/CompositeProduct/CompositeProduct";
import Cart from "./pages/Cart/Cart";
import "./styles/main.scss";
import Contacts from "./pages/Contacts/Contacts";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/catalog" element={<Catalog />} />
        <Route path="/categories/:categoryId" element={<Catalog />} />
        <Route
          path="/categories/:categoryId/subcategory/:subcategoryId"
          element={<Catalog />}
        />
        <Route path="/product/:productId" element={<Product />} />
        <Route
          path="/composite-product/:productId"
          element={<CompositeProduct />}
        />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/cart" element={<Cart />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
