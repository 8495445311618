import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useProductData from "../../hooks/dataFetching/useProductData";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import { useBreadcrumb } from "../../context/BreadcrumbContext";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Components from "./Components/Components";
import SelectedComponents from "./SelectedComponets/SelectedComponents";
import Details from "../../components/Details/Details";
import "./style.scss";

export default function CompositeProduct() {
  const { productId } = useParams();
  const { setProductCrumb } = useBreadcrumb();
  const { product } = useProductData(productId, "composite-product");
  const [selectedProducts, setSelectedProducts] = useState(() => {
    const localData = localStorage.getItem("selectedProducts");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    localStorage.setItem("selectedProducts", JSON.stringify(selectedProducts));
  }, [selectedProducts]);

  setProductCrumb(product);

  const addProductToSelection = (productToAdd) => {
    setSelectedProducts((prevSelectedProducts) => {
      const uniqueProductId = `${productToAdd.id}_${Date.now()}`;

      const productWithUniqueID = {
        ...productToAdd,
        uniqueId: uniqueProductId,
      };

      return [...prevSelectedProducts, productWithUniqueID];
    });
  };

  const removeProductFromSelection = (productId) => {
    const filteredProducts = selectedProducts.filter(
      (product) => product.uniqueId !== productId
    );
    setSelectedProducts(filteredProducts);
  };

  return (
    <div className="composite-product">
      {product && (
        <div className="container">
          <Breadcrumbs />
          <div className="row g-md-5">
            <div className="col-12 col-md-6">
              <ImageGallery images={product.images} />
            </div>
            <div className="col-12 col-md-6 description-section">
              <h3>{product.title}</h3>
              {product.vendor.name && (
                <div className="vendor">
                  <span className="title">Виробник:</span>
                  <span className="name">{product.vendor.name}</span>
                </div>
              )}
              <SelectedComponents
                selectedProducts={selectedProducts}
                removeProductFromSelection={removeProductFromSelection}
              />
              {product.detail && <Details details={product.detail} />}
            </div>
          </div>
          <Components
            products={product.products}
            addProductToSelection={addProductToSelection}
          />
        </div>
      )}
    </div>
  );
}
