import React from "react";
import { APIProvider, AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import "./style.scss";
import pin from "../../assets/logos/location-pin.png";

const googleMapsApiKey = "AIzaSyApc5kXaVR96ncVr82hlNIlqgfXmgPxRJ0";

function TheMap({ center }) {
  return (
    <APIProvider apiKey={googleMapsApiKey}>
      <Map center={center} zoom={14} className="map" mapId={"34ce9cf39acd6f00"}>
        <AdvancedMarker position={center}>
          <img src={pin} alt={"pin"} className="pin"></img>
        </AdvancedMarker>
      </Map>
    </APIProvider>
  );
}

export default React.memo(TheMap);
