import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useBreadcrumb } from "../../context/BreadcrumbContext";
import { useCart } from "../../context/CartContext";
import useProductData from "../../hooks/dataFetching/useProductData";
import ImageGallery from "../../components/ImageGallery/ImageGallery";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Parameters from "./Parameters/Parameters";
import Checkout from "./Checkout/Checkout";
import Details from "../../components/Details/Details";
import ProductAddedModal from "../../components/ProductAddedToCart/ProductAddedToCart";
import { Fabrics, Colors } from "./GroupedItems/GroupedItems";
import "./style.scss";

export default function Product() {
  const { productId } = useParams();
  const { product } = useProductData(productId, "product");
  const { setProductCrumb } = useBreadcrumb();
  const { addToCart } = useCart();
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedAddedPrice, setSelectedAddedPrice] = useState(null);
  const [selectedSizes, setSelectedSizes] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedFabric, setSelectedFabric] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceWithoutDiscount, setTotalPriceWithoutDiscount] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const calculateTotalPrice = useCallback(() => {
    const basePrice = selectedPrice || product.price;
    const addedPrice = (basePrice * selectedAddedPrice) / 100;
    const total = basePrice + addedPrice;
    setTotalPriceWithoutDiscount(total);

    if (product.is_on_sale) {
      const discountFactor = product.discount
        ? (100 - product.discount) / 100
        : 1;
      return total * discountFactor;
    }

    return total;
  }, [selectedPrice, selectedAddedPrice, product]);

  const handleParametersSelected = (parameter) => {
    setSelectedPrice(parameter.price || product.price);
    setSelectedSizes(parameter);
  };

  const handleColorSelected = (color, price) => {
    setSelectedColor(color);
    setSelectedAddedPrice(price);
  };

  const handleFabricSelected = (fabric, price) => {
    setSelectedFabric(fabric);
    setSelectedAddedPrice(price);
  };

  const handleAddToCart = () => {
    if (product && selectedPrice && selectedSizes) {
      const uniqueProductId = `${product.id}_${Date.now()}`;
      const itemToAdd = {
        productImage: product.images[0].image,
        productId: uniqueProductId,
        price: totalPrice,
        size: selectedSizes,
        title: product.title,
        fabric: selectedFabric,
        color: selectedColor,
      };
      addToCart(itemToAdd);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (product) {
      setProductCrumb(product);
      const newTotalPrice = calculateTotalPrice();
      setTotalPrice(newTotalPrice);
    }
  }, [product, setProductCrumb, calculateTotalPrice]);

  return (
    <div className="product">
      {product && (
        <div className="container">
          <Breadcrumbs />
          <div className="row g-md-5">
            <div className="col-12 col-md-6 sticky">
              <ImageGallery images={product.images} />
            </div>
            <div className="col-12 col-md-6 description-section">
              <div className="description-header">
                <h3>{product.title}</h3>
                {product.vendor.name && (
                  <div className="vendor">
                    <span className="title">Виробник:</span>
                    <span className="name">{product.vendor.name}</span>
                  </div>
                )}
                {product.is_on_sale && (
                  <div className="sale">
                    <span>На цей товар діє знижка {product.discount}%</span>
                    <span>Ціна без знижки {totalPriceWithoutDiscount} грн</span>
                  </div>
                )}
                <Checkout
                  totalPrice={totalPrice}
                  handleAddToCart={handleAddToCart}
                />
                <ProductAddedModal
                  show={showModal}
                  onHide={() => setShowModal(false)}
                />
              </div>
              <Parameters
                parameters={product.parameters}
                onParameterSelected={handleParametersSelected}
              />
              {product?.colors?.length > 0 && (
                <Colors
                  colors={product.colors}
                  onItemSelected={handleColorSelected}
                />
              )}
              {product?.fabrics?.length > 0 && (
                <Fabrics
                  fabrics={product.fabrics}
                  onItemSelected={handleFabricSelected}
                />
              )}
              {product.detail && <Details details={product.detail} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
