import TheMap from "../../../components/Map/Map";
import "./style.scss";

export default function Location({ marker, address, phone }) {
  return (
    <section className="row location">
      <div className="col-12 col-md-6 map-side">
        <TheMap center={marker} />
      </div>
      <div className="col-12 col-md-6 address-side">
        <h5>Адреса:</h5>
        <h6>{address}</h6>
        <h5>Телефон:</h5>
        <a href={`tel:${phone}`}>
          <h6>{phone}</h6>
        </a>
        <h5>Графік роботи магазину:</h5>
        <span>Без вихідних</span>
        <br />
        <span>З 10:00 до 20:00</span>
      </div>
    </section>
  );
}
