import Location from "./Location/Location";
import CallMeBack from "../../components/CallMeBack/CallMeBack";
import instagram from "../../assets/icons/instagram.svg";
import facebook from "../../assets/icons/square-facebook.svg";
import "./style.scss";

export default function Contacts() {
  const markerMykolaiv = { lat: 49.52946, lng: 23.980002 };
  const markerJudachiv = { lat: 49.389, lng: 24.138 };
  const markerJudachiv2 = { lat: 49.383, lng: 24.139 };
  const markerKhodoriv = { lat: 49.405091, lng: 24.312366 };

  return (
    <section className="contacts">
      <div className="container">
        <h3>Контакти</h3>
        <div className="row">
          <div className="col-12 col-md-6 mb-2">
            <h5>Телефон:</h5>
            <a href={`tel:0688180606`}>
              <h6>+38 068 818 06 06</h6>
            </a>
            <h5>Електронна пошта:</h5>
            <a href="mailto:shymanskaoksana@gmail.com">
              <h6>shymanskaoksana@gmail.com</h6>
            </a>
            <h5>Соціальні мережі:</h5>
            <a
              href="https://www.instagram.com/mebli.n_style?igsh=ZzV0amlnNGJlODRp"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icon" src={instagram} alt="instagram" />
            </a>
            <a
              href="https://www.instagram.com/mebli.n_style?igsh=ZzV0amlnNGJlODRp"
              target="_blank"
              rel="noreferrer"
            >
              <img className="social-icon" src={facebook} alt="facebook" />
            </a>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <CallMeBack />
          </div>
        </div>
        <h4>Адреси магазинів:</h4>
        <Location
          marker={markerMykolaiv}
          address={"м. Миколаїв, вул Львівська, 27"}
          phone={"0688180606"}
        />
        <Location
          marker={markerJudachiv}
          address={"м. Жидачів, вул Івана Франка, 6A"}
          phone={"0688180606"}
        />
        <Location
          marker={markerJudachiv2}
          address={"м. Жидачів, вул Івана Мазепи, 28"}
          phone={"0688180606"}
        />
        <Location
          marker={markerKhodoriv}
          address={"м. Миколаїв, вул Грушевського, 21"}
          phone={"0688180606"}
        />
      </div>
    </section>
  );
}
