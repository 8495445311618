import SimpleButton from "../../../components/buttons/SimpleButton/SimpleButton";
import { useCart } from "../../../context/CartContext";
import trash from "../../../assets/icons/trash.svg";
import "./style.scss";

export default function SelectedComponents({
  selectedProducts,
  removeProductFromSelection,
}) {
  const { addToCart } = useCart();
  const totalSum = selectedProducts.reduce(
    (accumulator, currentProduct) => accumulator + currentProduct.price,
    0
  );

  const handleAddToCart = (selectedProducts) => {
    selectedProducts.forEach((product) => {
      const uniqueProductId = `${product.id}_${Date.now()}`;
      const itemToAdd = {
        productImage: product.images[0].image,
        productId: uniqueProductId,
        price: product.price,
        size: product.size,
        title: product.title,
        fabric: product.fabric,
        color: product.color,
      };
      addToCart(itemToAdd);
    });
  };

  if (selectedProducts.length > 0) {
    return (
      <section className="selected-components">
        <div className="items">
          {selectedProducts.map((product, index) => (
            <div className="item" key={index}>
              <span>{product.title}</span>
              <div
                className="delete"
                onClick={() => removeProductFromSelection(product.uniqueId)}
              >
                <img src={trash} alt="trash icon" />
              </div>
            </div>
          ))}
        </div>
        <div className="summary">
          <div className="cost">
            <span className="label">Загальна вартість:</span>
            <div className="value">{totalSum} грн</div>
          </div>
          <SimpleButton
            title={"Додати у кошик"}
            onClick={() => handleAddToCart(selectedProducts)}
            className={"add-button"}
          />
        </div>
      </section>
    );
  }
}
