// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details {
  margin-bottom: 1rem;
}
.details .line div {
  padding: 0 !important;
  border: none !important;
  background: transparent !important;
}
.details p {
  text-align: start;
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Details/style.scss"],"names":[],"mappings":"AAEA;EACE,mBAAA;AADF;AAGI;EACE,qBAAA;EACA,uBAAA;EACA,kCAAA;AADN;AAIE;EACE,iBAAA;EACA,UAAA;AAFJ","sourcesContent":["@import \"../../styles/variables\";\n\n.details {\n  margin-bottom: 1rem;\n  .line {\n    div {\n      padding: 0 !important;\n      border: none !important;\n      background: transparent !important;\n    }\n  }\n  p {\n    text-align: start;\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
