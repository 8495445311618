import SimpleButton from "../../../components/buttons/SimpleButton/SimpleButton";
import "./style.scss";

export default function Checkout({ totalPrice, handleAddToCart }) {
  return (
    <section className="checkout">
      <div className="price">
        {totalPrice}
        <span>грн</span>
      </div>
      <SimpleButton
        className="buy-button"
        title={"Купити"}
        onClick={handleAddToCart}
      />
    </section>
  );
}
