import SimpleButton from "../buttons/SimpleButton/SimpleButton";
import { useNavigate } from "react-router-dom";
import "./style.scss";

export default function Welcome() {
  const navigate = useNavigate();

  const handleElementClick = (id) => {
    navigate("/catalog");
  };

  return (
    <section className="welcome container">
      <div className="slogan">
        <h1>N-STYLE: Де дизайн зустрічається з якістю</h1>
      </div>

      <SimpleButton
        className={"welcome-btn"}
        title="Перейти до каталогу"
        onClick={handleElementClick}
      />
    </section>
  );
}
