import React from "react";
import { Link } from "react-router-dom";
import { useBreadcrumb } from "../../context/BreadcrumbContext";
import "./style.scss";

const Breadcrumbs = () => {
  const { getBreadcrumbs } = useBreadcrumb();
  const breadcrumbs = getBreadcrumbs();

  return (
    <>
      {breadcrumbs.length > 1 && (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            {breadcrumbs.map((crumb, index) => (
              <li
                key={index}
                className={`breadcrumb-item ${
                  index === breadcrumbs.length - 1 ? "active" : ""
                }`}
              >
                <Link onClick={crumb.onClick} to={crumb.path}>
                  {crumb.title}
                </Link>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  );
};

export default Breadcrumbs;
