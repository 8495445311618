import { createContext, useState, useContext } from "react";

const SubcategoryContext = createContext(null);

export const useSubcategory = () => useContext(SubcategoryContext);

export const SubcategoryProvider = ({ children }) => {
  const [selectedSubcategoryId, setSelectedSubcategoryId] = useState(null);

  const value = {
    selectedSubcategoryId,
    setSelectedSubcategoryId,
  };

  return (
    <SubcategoryContext.Provider value={value}>
      {children}
    </SubcategoryContext.Provider>
  );
};
