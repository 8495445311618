import { useState, useEffect } from "react";
import { useProduct } from "../../context/ProductContext";
import axios from "axios";

export default function useProductData(productId, productType) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { product, setProduct } = useProduct();
  const url = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    setError(null);
    setProduct(null);

    const fetchProduct = async () => {
      try {
        const endpoint =
          productType === "composite-product" ? "composite-product" : "product";

        const response = await axios.get(
          `${url}/api/${endpoint}/${productId}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setProduct(response.data);
      } catch (err) {
        setError("Error fetching product data");
      } finally {
        setLoading(false);
      }
    };

    if (productId) {
      fetchProduct();
    }

    return () => {
      setProduct(null);
    };
  }, [productId, productType, url, setProduct]);

  return { product, loading, error };
}
