import React, { createContext, useContext, useState } from "react";
import { useCategory } from "./CategoryContext";
import { useSubcategory } from "./SubcategoryContext";

const BreadcrumbContext = createContext();

export const useBreadcrumb = () => useContext(BreadcrumbContext);

export const BreadcrumbProvider = ({ children }) => {
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [product, setProduct] = useState(null);
  const { setSelectedCategoryId } = useCategory();
  const { setSelectedSubcategoryId } = useSubcategory();

  const setCategoryCrumb = (selectedCategory) => {
    setCategory(selectedCategory);
    setSubcategory(null);
    setProduct(null);
  };

  const setSubcategoryCrumb = (selectedSubcategory) => {
    setSubcategory(selectedSubcategory);
    setProduct(null);
  };

  const setProductCrumb = (selectedProduct) => {
    setProduct(selectedProduct);
  };

  const getBreadcrumbs = () => {
    const breadcrumbs = [
      {
        title: "Головна",
        path: "/",
        onClick: () => {
          setCategory(null);
          setSubcategory(null);
          setProduct(null);
        },
      },
    ];
    if (category) {
      breadcrumbs.push({
        title: category.title,
        path: `/categories/${category.id}`,
        onClick: () => {
          setSelectedCategoryId(category.id);
          setSubcategory(null);
          setProduct(null);
        },
      });
    }
    if (subcategory) {
      breadcrumbs.push({
        title: subcategory.title,
        path: `/categories/${category.id}/subcategory/${subcategory.id}`,
        onClick: () => {
          setSelectedSubcategoryId(subcategory.id);
          setProduct(null);
        },
      });
    }
    if (product) {
      breadcrumbs.push({
        title: product.title,
        path: `/product/${product.id}`,
      });
    }
    return breadcrumbs;
  };

  return (
    <BreadcrumbContext.Provider
      value={{
        setCategoryCrumb,
        setSubcategoryCrumb,
        setProductCrumb,
        getBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};
