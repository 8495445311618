import { useState, useEffect } from "react";
import axios from "axios";

export default function useProposals() {
  const url = process.env.REACT_APP_BACKEND_URL;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [proposals, setProposals] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${url}/api/products/proposals`, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setProposals(response.data);
      } catch (err) {
        setError("Error fetching product data");
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [url, setProposals, setError, setLoading]);

  return { proposals, loading, error };
}
