// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart-parameters {
  font-size: 0.6rem;
}
.cart-parameters .number {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/Cart/CartParameters/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".cart-parameters {\n  font-size: 0.6rem;\n  .number {\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
