import Badge from "./Badge/Badge";
import selfPickUp from "../../../src/assets/icons/badges/truck-ramp-box-solid.svg";
import eco from "../../../src/assets/icons/badges/leaf-solid.svg";
import delivery from "../../../src/assets/icons/badges/truck-fast-solid.svg";
import guarantee from "../../../src/assets/icons/badges/shield-halved-solid.svg";
import store from "../../../src/assets/icons/badges/store.svg";
import headset from "../../../src/assets/icons/badges/headset.svg";
import screwdriver from "../../../src/assets/icons/badges/screwdriver.svg";
import custom from "../../../src/assets/icons/badges/pen-ruler.svg";

const badges = [
  { icon: selfPickUp, title: "Cамовивіз" },
  { icon: screwdriver, title: "Монтаж та збірка" },
  { icon: custom, title: "Виробництво на замовлення" },
  { icon: guarantee, title: "Гарантія від виробника" },
  { icon: delivery, title: "Доставка по всій країні" },
  { icon: store, title: "Шоуруми" },
  { icon: headset, title: "Консультація дизайнера" },
  { icon: eco, title: "Екологічні матеріали" },
];

export default function Badges() {
  return (
    <section className="badges container">
      <div className="row">
        {badges.map((badge, index) => (
          <Badge icon={badge.icon} title={badge.title} key={index} />
        ))}
      </div>
    </section>
  );
}
