import React from "react";
import hugelogo from "../../assets/logos/hugelogo.png";
import "./style.scss";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="divider">
          <img src={hugelogo} alt="huge logo" className="logo" />
          <p>Інтернет-магазин N-style © 2023-2024</p>
        </div>
        <div className="divider">
          <ul>
            <li>
              <a href="/contacts">Контакти</a>
            </li>
            <li>
              <a href="/about"> Про нас</a>
            </li>
            <li>
              <a href="/catalog">Каталог</a>
            </li>
          </ul>
          <span>Розроблено:</span>
          <a className="convalia-link" href="https://convalia.digital">
            convalia.digital
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
