// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components {
  margin: 2rem 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/CompositeProduct/Components/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF","sourcesContent":[".components {\n  margin: 2rem 0;\n}\n\n@media (min-width: 576px) {\n}\n\n@media (min-width: 768px) {\n}\n\n@media (min-width: 992px) {\n}\n\n@media (min-width: 1200px) {\n}\n\n@media (min-width: 1400px) {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
