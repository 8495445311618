import React, { useState } from "react";
import CartParameters from "./CartParameters/CartParameters";
import axios from "axios";
import { useCart } from "../../context/CartContext";
import trash from "../../assets/icons/trash.svg";
import CallMeBack from "../../components/CallMeBack/CallMeBack";
import "./style.scss";
import CheckoutSection from "./CartCheckout/CheckoutSection";

const Cart = () => {
  const { cartItems, removeFromCart } = useCart();
  const [modalOpen, setModalOpen] = useState(false);
  const url = process.env.REACT_APP_BACKEND_URL;

  const totalPrice = cartItems.reduce((total, item) => total + item.price, 0);
  console.log(cartItems);

  const handleSendOrderToBackend = async (customerName, customerPhone) => {
    try {
      const itemsToSend = cartItems.map((item) => {
        const { productImage, productId, ...itemWithoutUnneededFields } = item;
        return itemWithoutUnneededFields;
      });

      const orderData = {
        items: itemsToSend,
        totalPrice: itemsToSend.reduce((total, item) => total + item.price, 0),
        customerName: customerName,
        customerPhone: customerPhone,
      };

      const response = await axios.post(
        url + "/api/receive_order/",
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error("Error sending order to backend:", error);
    }
  };

  const handleOrder = (name, phone) => {
    handleSendOrderToBackend(name, phone);
    setModalOpen(false);
  };

  return (
    <div className="cart">
      {cartItems.length > 0 ? (
        <div className="container">
          <div className="row">
            <section className="col-12 col-md-4 order-md-2 ">
              {totalPrice > 0 && (
                <CheckoutSection
                  totalPrice={totalPrice}
                  setModalOpen={setModalOpen}
                  modalOpen={modalOpen}
                  handleOrder={handleOrder}
                />
              )}
            </section>
            <section className="col-md-8 order-md-1 items-section">
              <div className="row">
                {cartItems.map((item) => (
                  <div key={item.id} className="col-12 col-md-6">
                    <div className="card">
                      <img src={item.productImage} alt={item.title} />

                      <div className="board">
                        <div className="name-price">
                          <span>{item.title}</span>
                          <span>{item.price} грн</span>
                        </div>
                        <CartParameters
                          size={item.size}
                          color={item.color}
                          fabric={item.fabric}
                        />
                        <div
                          className="trash-icon"
                          onClick={() => removeFromCart(item.productId)}
                        >
                          <img
                            src={trash}
                            alt="Іконка смітника для видалення"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>
        </div>
      ) : (
        <section className="empty-cart">
          <h4>Ще нічого не вибрали? Перегляньте наші пропозиції в каталозі!</h4>
          <CallMeBack />
        </section>
      )}
    </div>
  );
};

export default Cart;
