import React from "react";
import Categories from "../../components/Categories/Categories";
import Subcategories from "../../components/Subcategories/Subcategories";
import Products from "../Products/Products";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";
import "./style.scss";

const Home = () => {
  const location = useLocation();
  const shouldRenderSubcategories = location.pathname === "/";

  return (
    <div className="catalog">
      <div className="container">
        <Breadcrumbs />
        <Categories />
        {!shouldRenderSubcategories && <Subcategories />}
        <Products />
      </div>
    </div>
  );
};

export default Home;
