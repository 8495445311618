import React from "react";
import SimpleButton from "../../components/buttons/SimpleButton/SimpleButton";
import { Link } from "react-router-dom";
import "./style.scss";

export default function Card({ product, index }) {
  const isComposite = Array.isArray(product.products);
  const productPath = isComposite
    ? `/composite-product/${product.id}`
    : `/product/${product.id}`;

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <Link to={productPath}>
        <div className="product-card" key={index}>
          {product.images && product.images.length > 0 && (
            <img
              className="image"
              src={product.images[0].image}
              alt={`Product ${product.title}`}
            />
          )}
          <div className="details">
            <div className="text">
              <span className="title">{product.title}</span>
              {product.price && <span>{product.price} грн</span>}
            </div>
            <SimpleButton title={"Купити"} className={"buy-button"} />
          </div>
        </div>
      </Link>
    </div>
  );
}
