import useSales from "../../hooks/dataFetching/useSales";
import Carousel from "../HomeCarousel/Carousel";
import "./style.scss";

export default function Sales() {
  const { sales } = useSales();

  return (
    <section className="sales container">
      <h4>Акції</h4>
      {sales && <Carousel items={sales} />}
    </section>
  );
}
