import React, { useEffect, useState } from "react";
import axios from "axios";
import "./style.scss";
import Card from "../../components/Card/Card";
import { useParams } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";

const Products = () => {
  const [products, setProducts] = useState([]);
  const { categoryId, subcategoryId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const url = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchProducts = async (page) => {
      let fetchUrl = `${url}/api/category/${categoryId}/products?page=${page}`;

      if (subcategoryId) {
        fetchUrl = `${url}/api/subcategory/${subcategoryId}/products?page=${page}`;
      }

      try {
        const response = await axios.get(fetchUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        setProducts(response.data.results);
        const totalPages = Math.ceil(response.data.count / 12);
        setTotalPages(totalPages);
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]);
      }
    };

    if (subcategoryId || categoryId) {
      fetchProducts(currentPage);
    }
  }, [currentPage, subcategoryId, categoryId, url]);

  useEffect(() => {
    setCurrentPage(1);
  }, [subcategoryId, categoryId]);

  console.log("Категорія: ", categoryId);
  console.log("Підкатегорія: ", subcategoryId);

  return categoryId !== undefined ? (
    <div className="container">
      <div className="cards">
        <div className="row">
          {products &&
            products.map((product) => (
              <Card key={product.id} product={product} />
            ))}
        </div>
        <Pagination
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  ) : (
    <div className="container">
      <div className="placetext">
        <h4>Будь ласка, виберіть категорію товарів для продовження!</h4>
      </div>
    </div>
  );
};

export default Products;
