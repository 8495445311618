import { createContext, useContext, useState } from "react";

// Create the product context
const ProductContext = createContext();

// Create a provider component to wrap your application with
export const ProductProvider = ({ children }) => {
  // Define the product state and set initial values
  const [product, setProduct] = useState(null);

  // You can also define functions to update the product state here

  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      {children}
    </ProductContext.Provider>
  );
};

// Create a custom hook to access the product context
export const useProduct = () => {
  return useContext(ProductContext);
};
